"use client";

import { Avatar, AvatarGroup } from "@chakra-ui/react";

const AvatarGroupClient = () => {
  return (
    <AvatarGroup size="md" max={4}>
      <Avatar src="/assets/images/avatar_08.png" />
      <Avatar src="/assets/images/avatar_07.png" />
      <Avatar src="/assets/images/avatar_02.png" />
      <Avatar src="/assets/images/avatar_04.png" />
    </AvatarGroup>
  );
};

export default AvatarGroupClient;
