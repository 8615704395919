import { REQUIRED_FIELD } from "@/lib/constants";
import { Input } from "@chakra-ui/react";
import { InputMask } from "@react-input/mask";

export const PhoneNumberMask = {
  mask: "(___) ___-____",
  replacement: { _: /\d/ },
};

const PhoneNumberInput = ({
  register,
  fieldName = "PhoneNumber",
  placeholder = "(123) 456-7890",
  size = "md",
}: {
  register: Function;
  fieldName?: string;
  placeholder?: string;
  size?: "md" | "lg";
}) => {
  return (
    <Input
      type="text"
      placeholder={placeholder}
      size={size}
      as={InputMask}
      mask={PhoneNumberMask.mask}
      replacement={PhoneNumberMask.replacement}
      {...register(fieldName, {
        required: REQUIRED_FIELD,
        minLength: {
          value: 10,
          message: "Phone number must be 10 digits",
        },
        maxLength: {
          value: 10,
          message: "Phone number must be 10 digits",
        },
        setValueAs: (value: string) => value.replace(/\D/g, ""),
      })}
    />
  );
};

export default PhoneNumberInput;
