import { GetCommonAllLocations } from "@/actions/common/GetCommonAllLocations";
import handleServerAction from "@/lib/hooks/handleServerAction";
import { Select, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type CommonLocation = {
  id: number;
  name: string;
  abbreviations: string;
};

const LocationSelect = ({
  value,
  onChange,
  size = "md",
  valueKey = "id",
  includeUnknown = false,
}: {
  value: number | string | undefined;
  onChange: (val: number | string) => void;
  size?: "sm" | "md" | "lg";
  valueKey?: "id" | "name";
  includeUnknown?: boolean;
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<CommonLocation[]>([]);

  const getLocations = async () => {
    try {
      const response = await handleServerAction(GetCommonAllLocations);
      setOptions(response);
      setIsLoading(false);
    } catch (err) {
      toast({
        status: "error",
        title: "Error Occured",
        description: err.message,
      });
    }
  };
  useEffect(() => {
    getLocations();
  }, []);

  const handleChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    if (valueKey === "id") {
      onChange(parseInt(ev.target.value));
    } else {
      onChange(ev.target.value);
    }
  };

  return (
    <Select
      aria-label="State"
      size={size}
      value={value || ""}
      {...(value === undefined && { color: "gray.500" })}
      isDisabled={isLoading}
      onChange={handleChange}
    >
      <option value="" disabled>
        Choose State
      </option>
      {options
        .filter((loc) => (includeUnknown ? true : loc.name !== "Unknown"))
        .map((loc) => (
          <option key={loc.id} value={loc[valueKey]}>
            {loc.name}
          </option>
        ))}
    </Select>
  );
};

export default LocationSelect;
